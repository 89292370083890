<template>
  <Base :routerName="$route.name" ref="base">
    <div id="content">
      <h2>ข่าวสาร</h2>
      <div class="row" v-show="isShow">
        <div class="column">
          <div
            v-for="leftNews in leftColumn"
            :key="leftNews.id"
            @click="selectImage(leftNews)"
            class="clickable"
          >
            <img
              :src="leftNews.news_image"
              alt="News"
              class="default-img"
            />
          </div>
        </div>
        <div class="column">
          <div
            v-for="rightNews in rightColumn"
            :key="rightNews.id"
            @click="selectImage(rightNews)"
            class="clickable"
          >
            <img
              :src="rightNews.news_image"
              alt="News"
              class="default-img"
            />
          </div>
        </div>
      </div>
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
      <button @click="addNews">เพิ่ม</button>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import Page from "@/components/Page.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      leftColumn: [],
      rightColumn: [],
      isShow: false,
      lastPage: 0,
      pages: [],
    };
  },
  components: {
    Base,
    Page,
  },
  mounted() {
    API.get("admin/news", true).then((response) => {
      if (response.data.length > 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      for (let i = 0; i < response.data.length; i++) {
        if (i % 2 === 0) {
          console.log('test');
          this.leftColumn.push(response.data[i]);
        } else {
          console.log('test2');
          this.rightColumn.push(response.data[i]);
        }
      }
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    updatePage(page) {
      API.get(`admin/news?page=${page}`, true).then((response) => {
        if (response.data.length > 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }
        for (let i = 0; i < response.data.length; i++) {
          if (i % 2 === 0) {
            this.leftColumn.push(response.data[i]);
          } else {
            this.rightColumn.push(response.data[i]);
          }
        }
      });
    },
    selectImage(data) {
      this.$refs.base.showPopup(
        true,
        "news-edit",
        "แก้ไขข่าวสาร",
        data,
        (image) => {
          let formData = new FormData();
          formData.append("news_image", image);
          API.post(
            `admin/editNews/${data.id}`,
            true,
            formData,
            true
          ).then((response) => {
            if (response.isSuccess) {
              location.reload();
            } else {
              this.$refs.base.showPopup(
                true,
                "confirm",
                response.response.data.message
              );
            }
          });
        },
        () => {
          API.delete(`admin/deleteNews/${data.id}`, true, null).then(
            (response) => {
              if (response.isSuccess) {
                location.reload();
              } else {
                this.$refs.base.showPopup(
                  true,
                  "confirm",
                  response.response.data.message
                );
              }
            }
          );
        }
      );
    },
    addNews() {
      this.$refs.base.showPopup(true, "news", "เพิ่มข่าวสาร", null, (image) => {
        let formData = new FormData();
        formData.append("news_image", image);
        API.post("admin/addNews", true, formData).then((response) => {
          if (response.isSuccess) {
            location.reload();
          } else {
            this.$refs.base.showPopup(
              true,
              "confirm",
              response.response.data.message
            );
          }
        });
      });
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}
.row {
  display: flex;
  padding: 0 4px 32px 4px;
}
.column {
  float: left;
  width: 50%;
  height: 50%;
  padding: 10px;
}
.column img {
  border-radius: 20px;
  margin-top: 8px;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

button {
  width: 140px;
  height: 44px;
  border-radius: 20px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  font-size: 18px;
  border: 2px solid #5dacc3;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
  float: right;
}
</style>
