<template>
  <img src="../assets/logo2.png" alt="Logo2" class="default-img" />
  <a href="/">
    <span
      ><img
        src="../assets/dashboard.png"
        class="default-img side-icon"
        :class="{ selected: isHomeSelected }"
    /></span>
    <span :class="{ selected: isHomeSelected }">สรุปข้อมูล</span>
  </a>
  <a href="#" @click="handleShowPersonal">
    <span
      ><img
        src="../assets/personal_icon.png"
        class="default-img side-icon" /></span
    >ข้อมูลผู้ใช้
    <span
      ><img
        :src="require('@/assets/' + arrowIconPersonal)"
        class="default-img side-icon arrow-icon"
    /></span>
  </a>
  <div v-if="isShowPersonal">
    <ul class="user-expand">
      <li>
        <a href="/user">
          <span
            ><img
              src="../assets/user_icon.png"
              class="default-img side-icon"
              :class="{ selected: isUserSelected }"
          /></span>
          <span class="sidenav-text-span" :class="{ selected: isUserSelected }"
            >ผู้ใช้</span
          >
        </a>
      </li>
      <li>
        <a href="/magicial">
          <span
            ><img
              src="../assets/magicial_icon.png"
              class="default-img side-icon"
              :class="{ selected: isMagicialelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isMagicialelected }"
            >หมอดู</span
          >
        </a>
      </li>
    </ul>
  </div>
  <a href="#" @click="handleShowManage">
    <span
      ><img
        src="../assets/manage_icon.png"
        class="default-img side-icon" /></span
    >จัดการข้อมูล
    <span
      ><img
        :src="require('@/assets/' + arrowIconManage)"
        class="default-img side-icon arrow-icon"
    /></span>
  </a>

  <div v-if="isShowManage">
    <ul class="user-expand">
      <li>
        <a href="/type">
          <span
            ><img
              src="../assets/type_icon.png"
              class="default-img side-icon"
              :class="{ selected: isTypeSelected }"
          /></span>
          <span class="sidenav-text-span" :class="{ selected: isTypeSelected }"
            >ศาสตร์ดูดวง</span
          >
        </a>
      </li>
      <li>
        <a href="/ability">
          <span
            ><img
              src="../assets/ability_icon.png"
              class="default-img side-icon"
              :class="{ selected: isAbilitySelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isAbilitySelected }"
            >ความถนัด</span
          >
        </a>
      </li>
      <li>
        <a href="/package">
          <span
            ><img
              src="../assets/package_icon.png"
              class="default-img side-icon"
              :class="{ selected: isPackageSelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isPackageSelected }"
            >แพคเกจ</span
          >
        </a>
      </li>
      <li>
        <a href="/news">
          <span
            ><img
              src="../assets/news_icon.png"
              class="default-img side-icon"
              :class="{ selected: isNewsSelected }"
          /></span>
          <span class="sidenav-text-span" :class="{ selected: isNewsSelected }"
            >ข่าวสาร</span
          >
        </a>
      </li>
    </ul>
  </div>
  <a href="/history" :class="{ selected: isHistorySelected }">
    <span
      ><img
        src="../assets/history_icon.png"
        class="default-img side-icon"
        :class="{ selected: isHistorySelected }"
    /></span>
    <span :class="{ selected: isHistorySelected }">ประวัติ</span>
  </a>
  <a href="#" @click="handleShowSetting">
    <span
      ><img
        src="../assets/setting_icon.png"
        class="default-img side-icon" /></span
    >การตั้งค่า<span
      ><img
        :src="require('@/assets/' + arrowIconSetting)"
        class="default-img side-icon arrow-icon" /></span
  ></a>
  <div v-if="isShowSetting">
    <ul class="user-expand">
      <li>
        <a href="/about-me">
          <span
            ><img
              src="../assets/aboutme_icon.png"
              class="default-img side-icon"
              :class="{ selected: isAboutMeSelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isAboutMeSelected }"
            >เกี่ยวกับเรา</span
          >
        </a>
      </li>
      <li>
        <a href="/question-and-answer">
          <span
            ><img
              src="../assets/qanda_icon.png"
              class="default-img side-icon"
              :class="{ selected: isQnASelected }"
          /></span>
          <span class="sidenav-text-span" :class="{ selected: isQnASelected }"
            >คำถามที่พบบ่อย</span
          >
        </a>
      </li>
      <li>
        <a href="/policy">
          <span
            ><img
              src="../assets/policy_icon.png"
              class="default-img side-icon"
              :class="{ selected: isPolicySelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isPolicySelected }"
            >นโยบายความเป็นส่วนตัว</span
          >
        </a>
      </li>
      <li>
        <a href="/term-and-condition">
          <span
            ><img
              src="../assets/term_icon.png"
              class="default-img side-icon"
              :class="{ selected: isTermAndConditionSelected }"
          /></span>
          <span
            class="sidenav-text-span"
            :class="{ selected: isTermAndConditionSelected }"
            >ข้อกำหนดและเงื่อนไขการใช้บริการ</span
          >
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["routerName"],
  data() {
    return {
      arrowIconPersonal: "arrow_down.png",
      arrowIconManage: "arrow_down.png",
      arrowIconSetting: "arrow_down.png",

      isShowPersonal: false,
      isShowManage: false,
      isShowSetting: false,

      isHomeSelected: false,
      isUserSelected: false,
      isMagicialelected: false,
      isTypeSelected: false,
      isAbilitySelected: false,
      isPackageSelected: false,
      isNewsSelected: false,
      isHistorySelected: false,
      isAboutMeSelected: false,
      isQnASelected: false,
      isPolicySelected: false,
      isTermAndConditionSelected: false,
    };
  },
  mounted() {
    if (
      this.routerName === "home" ||
      this.routerName === "homeScore" ||
      this.routerName === "homeType" ||
      this.routerName === "homeTime" ||
      this.routerName === "homeSalary" || 
      this.routerName === "magicialDetail"
    ) {
      this.isHomeSelected = true;
    } else if (
      this.routerName === "userView" ||
      this.routerName === "magicialView"
    ) {
      if (this.routerName === "userView") {
        this.isUserSelected = true;
      } else {
        this.isMagicialelected = true;
      }
      this.isShowPersonal = !this.isShowPersonal;
      if (this.isShowPersonal) {
        this.arrowIconPersonal = "arrow_up.png";
      } else {
        this.arrowIconPersonal = "arrow_down.png";
      }
    } else if (
      this.routerName === "typeView" ||
      this.routerName === "packageView" ||
      this.routerName === "newsView" ||
      this.routerName === "abilityView"
    ) {
      if (this.routerName === "typeView") {
        this.isTypeSelected = true;
      } else if (this.routerName === "packageView") {
        this.isPackageSelected = true;
      } else if (this.routerName === "abilityView") {
        this.isAbilitySelected = true;
      } else {
        this.isNewsSelected = true;
      }
      this.isShowManage = !this.isShowManage;
      if (this.isShowManage) {
        this.arrowIconManage = "arrow_up.png";
      } else {
        this.arrowIconManage = "arrow_down.png";
      }
    } else if (this.routerName === "historyView") {
      this.isHistorySelected = true;
    } else {
      if (this.routerName === "aboutMeView") {
        this.isAboutMeSelected = true;
      } else if (this.routerName === "questionAndAnswerView") {
        this.isQnASelected = true;
      } else if (this.routerName === "policyView") {
        this.isPolicySelected = true;
      } else {
        this.isTermAndConditionSelected = true;
      }
      this.isShowSetting = !this.isShowSetting;
      if (this.isShowSetting) {
        this.arrowIconSetting = "arrow_up.png";
      } else {
        this.arrowIconSetting = "arrow_down.png";
      }
    }
  },
  methods: {
    handleShowPersonal() {
      this.isShowPersonal = !this.isShowPersonal;
      if (this.isShowPersonal) {
        this.arrowIconPersonal = "arrow_up.png";
      } else {
        this.arrowIconPersonal = "arrow_down.png";
      }
    },
    handleShowManage() {
      this.isShowManage = !this.isShowManage;
      if (this.isShowManage) {
        this.arrowIconManage = "arrow_up.png";
      } else {
        this.arrowIconManage = "arrow_down.png";
      }
    },
    handleShowSetting() {
      this.isShowSetting = !this.isShowSetting;
      if (this.isShowSetting) {
        this.arrowIconSetting = "arrow_up.png";
      } else {
        this.arrowIconSetting = "arrow_down.png";
      }
    },
  },
};
</script>

<style scoped>
.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 25px;
  color: white;
  display: flex;
  transition: 0.3s;
  align-items: center;
}

.sidenav img {
  padding: 8px 8px 8px 80px;
  display: flex;
}

.sidenav a .side-icon {
  padding: 10px;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #ffc000;
}

.side-icon {
  filter: brightness(0) invert(1);
}

.sidenav a:hover .side-icon {
  filter: none;
}

ul {
  list-style: none;
}

.selected {
  color: #ffc000;
  filter: none;
}
</style>