<template>
  <div class="sidenav" :class="{ active: isActive }">
    <SideBar :routerName="routerName" />
  </div>
  <div id="main" :class="{ active: isActive }">
    <div class="topnav">
      <button @click="openNav()">
        <img src="../assets/sidebar.png" class="default-img" />
      </button>
      <button @click="logout" class="split">
        <img src="../assets/logout.png" class="default-img" />
      </button>
    </div>

    <slot></slot>
  </div>

  <Popup
    v-if="visible"
    @close="closePopup"
    :showCloseIcon="showCloseIcon"
    :type="type"
    :text="text"
    :data="data"
    :method="method"
    :deleteMethod="deleteMethod"
  >
  </Popup>
</template>

<script scoped>
import SideBar from "@/components/SideBar.vue";
import API from "@/Service/Api";
import Popup from "@/components/Popup.vue";

export default {
  props: ["routerName"],
  data() {
    return {
      isActive: true,
      visible: false,
      showCloseIcon: false,
      type: null,
      text: null,
      data: null,
      method: null,
      deleteMethod: null,
    };
  },
  components: {
    SideBar,
    Popup,
  },
  methods: {
    openNav() {
      this.isActive = !this.isActive;
    },
    showPopup(showCloseIcon, type, text, data, method, deleteMethod) {
      this.showCloseIcon = showCloseIcon;
      this.type = type;
      this.text = text;
      this.data = data;
      this.method = method;
      this.deleteMethod = deleteMethod;
      this.visible = true;
    },
    closePopup() {
      this.visible = false;
    },
    logout() {
      this.showPopup(
        true,
        "confirmCancel",
        "ยืนยันออกจากระบบ",
        null,
        () => {
          API.post("admin/logout", false).then((response) => {
            if (response.isSuccess) {
              localStorage.removeItem("apiToken");
              this.$router.push({ path: `/login` });
            } else {
              //
            }
          });
        }
      );
    },
  },
};
</script>

<style>
#main {
  transition: margin-left 0.5s;
}

#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}

#main.active {
  margin-left: 300px;
}

body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #004a61;
  background: linear-gradient(
    0deg,
    rgba(0, 30, 47, 1) 0%,
    rgba(0, 74, 97, 1) 23.14%,
    rgba(49, 145, 168, 1) 61.97%,
    rgba(124, 192, 215, 1) 94.68%
  );
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 3.5rem);
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav.active {
  width: 300px;
}

.topnav {
  overflow: hidden;
  background-color: #eaeaea;
}

.topnav button {
  float: left;
  padding: 12px 16px;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: none;
  background: transparent;
}

.topnav button.split {
  float: right;
  padding-left: 10px;
}

.dropdownbtn {
  float: right;
}
#bottompage {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.exportbtn {
  float: right;
}
</style>