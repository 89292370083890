<template>
  <Base :routerName="$route.name">
    <div id="content">
      <DropdownBtn
        :data="dropdownOption[0]"
        :datas="dropdownOption"
        type="changeOption"
        class="dropdownbtn"
      />
      <br />
      <br />
      <br />
      <TableHeader
        :headerTitles="['จำนวนผู้ใช้งาน', 'จำนวนหมอดู', 'จำนวนผู้ชำระบริการ']"
        tableType="amoungOfUser"
        :tableDetailDatas="dashboardData"
      />
      <br />
      <br />
      <TableHeader
        id="magical-table"
        :headerTitles="['ชื่อ-นามสกุล', 'วันที่', 'สถานะ', '']"
        tableType="magicial"
        :tableDetailDatas="data"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import DropdownBtn from "@/components/DropdownButton.vue";
import Page from "@/components/Page.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      data: null,
      dashboardData: {},
      lastPage: 0,
      pages: [],
      dropdownOption: ["หน้าแรก", "คะแนน", "หมอดู", "เวลา", "รายได้"],
    };
  },
  components: {
    Base,
    TableHeader,
    DropdownBtn,
    Page,
  },
  mounted() {
    API.get("admin/dashboard", true).then((response) => {
      this.dashboardData = response.data;
    });
    API.get("admin/dashboard/magicals", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    updatePage(page) {
      API.get(`admin/dashboard/magicals?page=${page}`, true).then(
        (response) => {
          this.data = response.data;
        }
      );
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}
</style>
