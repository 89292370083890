import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import HomeScoreView from '@/views/HomeScoreView.vue'
import HomeTypeView from '@/views/HomeTypeView.vue'
import HomeTimeView from '@/views/HomeTimeView.vue'
import HomeSalaryView from '@/views/HomeSalaryView.vue'
import MagicialDetail from '@/views/MagicialDetailView.vue'
import UserView from '@/views/UserView.vue'
import MagicialView from '@/views/MagicialView.vue'
import TypeView from '@/views/TypeView.vue'
import PackageView from '@/views/PackageView.vue'
import AbilityView from '@/views/AbilityView.vue'
import NewsView from '@/views/NewsView.vue'
import HistoryView from '@/views/HistoryView.vue'
import AboutMeView from '@/views/AboutMeView.vue'
import QuestionAndAnswerView from '@/views/QuestionAndAnswerView.vue'
import PolicyView from '@/views/PolicyView.vue'
import TermAndConditionView from '@/views/TermAndConditionView.vue'
import LoginView from '@/views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home-score',
    name: 'homeScore',
    component: HomeScoreView
  },
  {
    path: '/home-type',
    name: 'homeType',
    component: HomeTypeView
  },
  {
    path: '/home-time',
    name: 'homeTime',
    component: HomeTimeView
  },
  {
    path: '/home-salary',
    name: 'homeSalary',
    component: HomeSalaryView
  },
  {
    path: '/magicial-detail/:id',
    name: 'magicialDetail',
    component: MagicialDetail
  },
  {
    path: '/user',
    name: 'userView',
    component: UserView
  },
  {
    path: '/magicial',
    name: 'magicialView',
    component: MagicialView
  },
  {
    path: '/type',
    name: 'typeView',
    component: TypeView
  },
  {
    path: '/package',
    name: 'packageView',
    component: PackageView
  },
  {
    path: '/ability',
    name: 'abilityView',
    component: AbilityView
  },
  {
    path: '/news',
    name: 'newsView',
    component: NewsView
  },
  {
    path: '/history',
    name: 'historyView',
    component: HistoryView
  },
  {
    path: '/about-me',
    name: 'aboutMeView',
    component: AboutMeView
  },
  {
    path: '/question-and-answer',
    name: 'questionAndAnswerView',
    component: QuestionAndAnswerView
  },
  {
    path: '/policy',
    name: 'policyView',
    component: PolicyView
  },
  {
    path: '/term-and-condition',
    name: 'termAndConditionView',
    component: TermAndConditionView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
