<template>
  <Base :routerName="$route.name">
    <div id="content">
      <DropdownBtn
        :data="dropdownOption[3]"
        :datas="dropdownOption"
        type="changeOption"
        class="dropdownbtn"
      />
      <br />
      <br />
      <br />
      <TableHeader
        v-show="isShow"
        :headerTitles="headerTitle"
        tableType="homeTime"
        :tableDetailDatas="data"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
      <br />
      <BottomBtn @onClickExport="exportExcel" class="exportbtn" />
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import DropdownBtn from "@/components/DropdownButton.vue";
import Page from "@/components/Page.vue";
import BottomBtn from "@/components/BottomButton.vue";
import API from "@/Service/Api";
import * as XLSX from "xlsx";
import moment from "moment";

export default {
  data() {
    return {
      data: null,
      headerTitle: ["หมอดู", "เวลาดูทั้งหมด(นาที)"],
      lastPage: 0,
      isShow: false,
      pages: [],
      dropdownOption: ["หน้าแรก", "คะแนน", "หมอดู", "เวลา", "รายได้"],
    };
  },
  components: {
    Base,
    TableHeader,
    DropdownBtn,
    Page,
    BottomBtn,
  },
  mounted() {
    API.get("admin/dashboard/magicial_time", true).then((response) => {
      this.data = response.data;
      if (response.data.length === 0) {
        this.isShow = false;
      } else {
        for (
          let i = 0;
          i < response.data[0].user_time_by_fortune_list.length;
          i++
        ) {
          this.headerTitle.push(
            response.data[0].user_time_by_fortune_list[i].fortune_telling_name
          );
        }
        this.isShow = true;
      }
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY--HH-mm-ss");
      }
    },
    exportExcel() {
      API.get("admin/dashboard/magicial_time_all", true).then((response) => {
        if (response.data === null || response.data.length <= 0) {
          return;
        } else {
          var exportData = [];
          var draftData = {};

          response.data.map((object) => {
            draftData = {
              หมอดู: object.magicial,
              'เวลาดูทั้งหมด(นาที)': object.user_time_all,
            };
            for (let i = 0; i < object.user_time_by_fortune_list.length; i++) {
              draftData[
                object.user_time_by_fortune_list[i].fortune_telling_name
              ] = object.user_time_by_fortune_list[i].user_count;
            }

            exportData.push(draftData);
          });
          const dataWS = XLSX.utils.json_to_sheet(exportData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(
            wb,
            `export-เวลา--${this.format_date(Date().toLocaleString())}.xlsx`
          );
        }
      });
    },
    updatePage(page) {
      API.get(`admin/dashboard/magicial_time?page=${page}`, true).then(
        (response) => {
          this.data = response.data;
        }
      );
    },
  },
};
</script>

<style scoped>
</style>