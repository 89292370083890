<template>
  <div class="dropdown">
    <button @click="showDropdown" class="dropbtn">
      <p v-if="selectData">{{ selectData }}</p>
      <p v-else>{{ data }}</p>
      <img src="../assets/option.png" class="default-img" />
    </button>
    <div v-if="isShow" class="dropdown-content">
      <div v-for="data in datas" :key="data">
        <p @click="selectObject(data)">{{ data }}</p>
        <div v-if="data != datas[datas.length - 1]">
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "datas", "type", "method"],
  data() {
    return {
      selectData: this.data,
      isShow: false,
    };
  },
  methods: {
    showDropdown() {
      this.isShow = !this.isShow;
    },
    closeDropdown() {
      this.isShow = false;
    },
    selectObject(value) {
      this.selectData = value;
      this.isShow = !this.isShow;
      if (this.type === "changeOption") {
        if (value === "คะแนน") {
          this.$router.push({ path: "/home-score" });
        } else if (value === "หมอดู") {
          this.$router.push({ path: "/home-type" });
        } else if (value === "เวลา") {
          this.$router.push({ path: "/home-time" });
        } else if (value === "รายได้") {
          this.$router.push({ path: "/home-salary" });
        } else {
            this.$router.push({ path: "/" });
        }
      } else {
        typeof this.method === "function" && this.method(value);
      }
    },
  },
};
</script>

<style scoped>
.dropbtn {
  background-color: white;
  color: #5dacc3;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  border-radius: 20px;
  font-family: "Kanit", sans-serif;
  height: 47px;
  width: 160px;
  cursor: pointer;
}

.dropbtn p {
  font-size: 18px;
  font-weight: 600;
  display: inline;
}

img {
  float: right;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: white;
  min-width: 150px;
  overflow: auto;
  border: 2px solid #5dacc3;
  border-radius: 10px;
  z-index: 1;
  margin: 16px 0px 0px 0px;
}

.dropdown-content p {
  font-size: 20px;
  color: #5dacc3;
  text-align: center;
}
hr {
  margin: 0 16px;
  border-top: 1px solid #5dacc3;
}
</style>