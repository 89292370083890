<template>
  <div class="page-container">
    <img
      src="../assets/fist_page.png"
      class="default-img clickable-image"
      @click="backToFirstPage"
    />
    <img
      src="../assets/previous.png"
      class="default-img clickable-image"
      @click="previousPage"
    />
    <div>
      <div class="selectWrapper">
        <select @change="selectPage" v-model="page">
          <option v-for="page in pages" :key="page" :value="page">
            {{ page }}
          </option>
        </select>
      </div>
      <p> of {{ lastPage }}</p>
    </div>
    <img
      src="../assets/next_page.png"
      class="default-img clickable-image"
      @click="nextPage"
    />
    <img
      src="../assets/last_page.png"
      class="default-img clickable-image"
      @click="goToLastPage"
    />
  </div>
</template>

<script>
export default {
  props: ["lastPage", "pages", "method"],
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    selectPage() {
      typeof this.method === "function" && this.method(this.page);
    },
    backToFirstPage() {
      this.page = 1;
      typeof this.method === "function" && this.method(this.page);
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
        typeof this.method === "function" && this.method(this.page);
      } else {
        return;
      }
    },
    nextPage() {
      if (this.page < this.lastPage) {
        this.page += 1;
        typeof this.method === "function" && this.method(this.page);
      } else {
        return;
      }
    },
    goToLastPage() {
      this.page = this.lastPage;
      typeof this.method === "function" && this.method(this.page);
    },
  },
};
</script>

<style scoped>
.page-container {
  display: inline-flex;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  padding: 0 8px;
}

.clickable-image {
  cursor: pointer;
  pointer-events: auto;
}

select {
  border: 0px;
  outline: none;
  cursor: pointer;
   position: relative;
  z-index: 99;
  background: none;
}
.selectWrapper{
  border-radius: 5px;
  display:inline-block;
  border: 0.5px solid #AAAAAA;
}
p {
  display: inline;
}
</style>