import axios from 'axios'
import router from '@/router'

export default {
    async get(path, isAuthen, params) {
        let headers = {
            Accept: "application/json",
        };

        if (isAuthen) {
            headers.Authorization = `Bearer ${localStorage.getItem("apiToken")}`
        }

        let response;
        try {
            response = await axios
                .get(process.env.VUE_APP_ROOT_API + path, {
                    headers,
                    params,
                });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                router.push({ name: 'login' })
            } else {
                return error;
            }
            const response = undefined;
        }
        return response.data;
    },
    async post(path, isAuthen, params, isMultipart) {
        let headers = {
            Accept: "application/json",
        };

        if (isAuthen) {
            headers.Authorization = `Bearer ${localStorage.getItem("apiToken")}`
        }

        if (isMultipart) {
            headers['Content-Type'] = 'multipart/form-data'
        }

        let response;
        try {
            response = await axios
                .post(process.env.VUE_APP_ROOT_API + path,
                    params, {
                    headers,
                });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                router.push({ name: 'login' })
            } else {
                return error;
            }
            const response = undefined;
        }
        return response.data;
    },
    put() {

    },
    async patch(path, isAuthen, params) {
        let headers = {
            Accept: "application/json",
        };

        if (isAuthen) {
            headers.Authorization = `Bearer ${localStorage.getItem("apiToken")}`
        }

        let response;
        try {
            response = await axios
                .patch(process.env.VUE_APP_ROOT_API + path,
                    params, {
                    headers,
                });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                router.push({ name: 'login' })
            } else {
                return error;
            }
            const response = undefined;
        }
        return response.data;
    },
    async delete(path, isAuthen, params) {


        let response;
        try {
            response = await axios.delete(process.env.VUE_APP_ROOT_API + path, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
                },
                data: {
                    params
                }
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                router.push({ name: 'login' })
            } else {
                return error;
            }
            const response = undefined;
        }
        return response.data;
    },
}