<template>
  <Base :routerName="$route.name" ref="base">
    <div id="content" v-show="isShow">
      <div class="background-boder">
        <div>
          <div class="row">
            <div class="column">
              <p>ชื่อบัญชีผู้ใช้</p>
              <p>ชื่อ-นามสกุล</p>
              <p>ธนาคาร</p>
              <p>เลขประจำตัวประชาชน</p>
              <p>อีเมล์</p>
            </div>
            <div class="column" v-if="data">
              <p class="black">{{ data.username }}</p>
              <p class="black">{{ data.first_name }} {{ data.last_name }}</p>
              <p class="black">{{ data.bank }}</p>
              <p class="black">{{ data.identification_no }}</p>
              <p class="black">{{ data.email }}</p>
            </div>
          </div>
          <div class="row">
            <div class="column-2" v-if="data">
              <p>หน้าสมุดบัญชีธนาคาร</p>
              <div class="img-container" @click="selectBankImage()">
                <img
                  :src="`data:image/jpeg;base64, ${data.bank_image}`"
                  alt="Image"
                  class="default-img"
                />
              </div>
            </div>
            <div class="column-2" v-if="data">
              <p>บัตรประจำตัวประชาชน</p>
              <div class="img-container" @click="selectIdImage()">
                <img
                  :src="`data:image/jpeg;base64, ${data.identification_image}`"
                  alt="Image"
                  class="default-img"
                />
              </div>
            </div>
          </div>
          <div class="bottom-btn">
            <button class="rejected-btn" @click="rejected">ส่งกลับ</button>
            <button class="approved-btn" @click="approved">เรียบร้อย</button>
          </div>
        </div>
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      data: null,
      isShow: false,
    };
  },
  components: {
    Base,
  },
  mounted() {
    API.get(`admin/magical/${this.$route.params.id}`, true).then((response) => {
      this.data = response.data;
      this.isShow = true;
    });
  },
  methods: {
    selectBankImage() {
      this.$refs.base.showPopup(true, "showImage", null, this.data.bank_image);
    },
    selectIdImage() {
      this.$refs.base.showPopup(
        true,
        "showImage",
        null,
        this.data.identification_image
      );
    },
    approved() {
      this.$refs.base.showPopup(
        true,
        "confirmCancel",
        "คุณแน่ใจหรือไม่",
        null,
        () => {
          API.patch("admin/magical/updateStatus", true, {
            id: this.$route.params.id,
            status: 'approved',
          }).then((response) => {
            if (response.isSuccess) {
              this.$router.go(-1)
            } else {
              this.$refs.base.showPopup(
                true,
                "confirm",
                response.response.data.message,
              );
            }
          });
        }
      );
    },
    rejected() {
      this.$refs.base.showPopup(
        true,
        "confirmCancel",
        "คุณแน่ใจหรือไม่",
        null,
        () => {
           API.patch("admin/magical/updateStatus", true, {
            id: this.$route.params.id,
            status: 'rejected',
          }).then((response) => {
            if (response.isSuccess) {
              this.$router.go(-1)
            } else {
              this.$refs.base.showPopup(
                true,
                "confirm",
                response.response.data.message,
              );
            }
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.background-boder {
  margin: 10px;
  border: solid 1px #5dacc3;
  padding: 10px 20px;
  border-radius: 20px;
}
.row {
  display: flex;
  padding: 0 4px 4px 4px;
}
.column {
  float: left;
  width: 20%;
  height: 50%;
  padding: 10px;
}

.column-2 {
  float: left;
  width: 50%;
  height: 50%;
  padding: 10px;
}

p {
  color: #5dacc3;
}

.black {
  color: black;
}

img {
  border-radius: 20px;
  margin-top: 8px;
  vertical-align: middle;
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.img-container {
  cursor: pointer;
}

.bottom-btn {
  padding: 10px;
  text-align: center;
}

.approved-btn {
  margin: 16px 10px 10px 40px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}
.rejected-btn {
  background-color: #e75454;
  color: white;
  padding: 8px 16px;
  border: 2px solid #e75454;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}
</style>