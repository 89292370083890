<template>
  <Base :routerName="$route.name">
    <div id="content">
      <DropdownBtn
        :data="dropdownOption[1]"
        :datas="dropdownOption"
        type="changeOption"
        class="dropdownbtn"
      />
      <DropdownBtn
        v-show="isShow"
        :data="types[0]"
        :datas="types"
        type="changeType"
        :method="selectType"
      />
      <br />
      <br />
      <TableHeader
        :headerTitles="['หมอดู', 'คะแนนเฉลี่ย', 'จำนวนผู้ใช้งานทั้งหมด']"
        tableType="homeScore"
        :tableDetailDatas="data"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
      <br />
      <BottomBtn @onClickExport="exportExcel" class="exportbtn" />
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import DropdownBtn from "@/components/DropdownButton.vue";
import Page from "@/components/Page.vue";
import BottomBtn from "@/components/BottomButton.vue";
import API from "@/Service/Api";
import * as XLSX from "xlsx";
import moment from "moment";

export default {
  data() {
    return {
      data: null,
      typeData: null,
      types: [],
      selectedType: null,
      isAllType: true,
      isShow: false,
      lastPage: 0,
      pages: [],
      dropdownOption: ["หน้าแรก", "คะแนน", "หมอดู", "เวลา", "รายได้"],
    };
  },
  components: {
    Base,
    TableHeader,
    DropdownBtn,
    Page,
    BottomBtn,
  },
  mounted() {
    API.get("admin/dashboard/scoreTotal", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
    API.get("fortuneTelling", false).then((response) => {
      this.typeData = response.data;
      this.types = response.data.map((object) => object.fortune_telling_name);
      this.types.unshift("ทั้งหมด");
      this.isShow = true;
    });
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY--HH-mm-ss");
      }
    },
    exportExcel() {
      if (this.isAllType) {
        API.get("admin/dashboard/scoreTotalAll", true).then((response) => {
          if (response.data === null || response.data.length <= 0) {
            return;
          } else {
            var exportData = [];
            response.data.map((object) => {
              exportData.push({
                หมอดู: object.magicial,
                คะแนนเฉลี่ย: object.rating,
                จำนวนผู้ใช้งานทั้งหมด: object.user_count,
              });
            });
            const dataWS = XLSX.utils.json_to_sheet(exportData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS);
            XLSX.writeFile(
              wb,
              `export-คะแนนทั้งหมด--${this.format_date(
                Date().toLocaleString()
              )}.xlsx`
            );
          }
        });
      } else {
        API.get("admin/dashboard/scoreAll", true, {
          fortune_id: this.selectedType.id,
        }).then((response) => {
          if (response.data === null || response.data.length <= 0) {
            return;
          } else {
            var exportData = [];
            response.data.map((object) => {
              exportData.push({
                หมอดู: object.magicial,
                คะแนนเฉลี่ย: object.rating,
                จำนวนผู้ใช้งานทั้งหมด: object.user_count,
              });
            });
            const dataWS = XLSX.utils.json_to_sheet(exportData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS);
            XLSX.writeFile(
              wb,
              `export-คะแนน-${this.selectedType.fortune_telling_name}--${this.format_date(
                Date().toLocaleString()
              )}.xlsx`
            );
          }
        });
      }
    },
    updatePage(page) {
      if (this.isAllType) {
        API.get(`admin/dashboard/scoreTotal?page=${page}`, true).then(
          (response) => {
            this.data = response.data;
          }
        );
      } else {
        let typeId = this.typeData.find(
          (object) => object.fortune_telling_name === type
        ).id;
        API.get(`admin/dashboard/score?page=${page}`, true, {
          fortune_id: typeId,
        }).then((response) => {
          this.data = response.data;
        });
      }
    },
    selectType(type) {
      if (type === "ทั้งหมด") {
        this.isAllType = true;
      } else {
        this.isAllType = false;
      }
      this.updateData(type);
    },
    updateData(type) {
      if (this.isAllType) {
        API.get("admin/dashboard/scoreTotal", true).then((response) => {
          this.data = response.data;
          this.lastPage = response.meta.last_page;
          for (let i = 0; i < this.lastPage; i++) {
            this.pages.push(i + 1);
          }
        });
      } else {
        this.selectedType = this.typeData.find(
          (object) => object.fortune_telling_name === type
        );
        API.get("admin/dashboard/score", true, { fortune_id: this.selectedType.id }).then(
          (response) => {
            this.data = response.data;
            this.lastPage = response.meta.last_page;
            for (let i = 0; i < this.lastPage; i++) {
              this.pages.push(i + 1);
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped>
</style>