<template>
  <tr v-if="tableType === 'amoungOfUser'">
    <td>{{ tableDetailDatas.user_count }}</td>
    <td>{{ tableDetailDatas.magicial_count }}</td>
    <td>{{ tableDetailDatas.price_amount }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'magicial'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.id"
  >
    <td>{{ tableDetailData.name }}</td>
    <td>{{ format_date(tableDetailData.date) }}</td>
    <td>
      <button
        v-if="tableDetailData.register_status === 'waiting'"
        class="wating-btn"
      >
        รอตรวจสอบ
      </button>
      <button
        v-else-if="tableDetailData.register_status === 'approved'"
        class="approved-btn"
      >
        อนุมัติ
      </button>
      <button v-else class="rejected-btn">ส่งกลับ</button>
    </td>
    <td>
      <button
        @click="openMagicialDetail(tableDetailData.id)"
        class="detail-btn"
      >
        รายละเอียด
      </button>
    </td>
  </tr>

  <tr
    v-else-if="tableType === 'homeScore'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magicial"
  >
    <td>{{ tableDetailData.magicial }}</td>
    <td>{{ tableDetailData.rating.toFixed(2) }}</td>
    <td>{{ tableDetailData.user_count }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'homeType'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magical"
  >
    <td>{{ tableDetailData.magicial }}</td>
    <td>{{ tableDetailData.user_count_all }}</td>
    <td
      v-for="row in tableDetailData.user_count_by_fortune_list"
      :key="row.fortune_telling_name"
    >
      {{ row.user_count }}
    </td>
  </tr>

  <tr
    v-else-if="tableType === 'homeTime'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magical"
  >
    <td>{{ tableDetailData.magicial }}</td>
    <td>{{ tableDetailData.user_time_all }}</td>
    <td
      v-for="row in tableDetailData.user_time_by_fortune_list"
      :key="row.fortune_telling_name"
    >
      {{ row.user_count }}
    </td>
  </tr>

  <tr
    v-else-if="tableType === 'homeSalary'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magical"
  >
    <td>{{ tableDetailData.magicial }}</td>
    <td>{{ tableDetailData.salary }}</td>
    <td>{{ tableDetailData.user_count_all }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'user'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magical"
  >
    <td>{{ tableDetailData.first_name }} {{ tableDetailData.last_name }}</td>
    <td>{{ tableDetailData.email }}</td>
    <td>
      <button
        @click="unBan(tableDetailData.id)"
        v-if="tableDetailData.status === 'isBan'"
        class="rejected-btn canSelect"
      >
        ระงับ
      </button>
      <button
        @click="ban(tableDetailData.id)"
        v-else
        class="approved-btn canSelect"
      >
        ปกติ
      </button>
    </td>
  </tr>

  <tr
    v-else-if="tableType === 'magiciallist'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.magical"
  >
    <td>{{ tableDetailData.name }}</td>
    <td>{{ tableDetailData.email }}</td>
    <td>
      <button
        @click="unBan(tableDetailData.id)"
        v-if="tableDetailData.status === 'isBan'"
        class="rejected-btn canSelect"
      >
        ระงับ
      </button>
      <button
        @click="ban(tableDetailData.id)"
        v-else
        class="approved-btn canSelect"
      >
        ปกติ
      </button>
    </td>
  </tr>

  <tr
    v-else-if="tableType === 'type'"
    class="canSelect"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.id"
    @click="selectRow(tableDetailData)"
  >
    <td>
      <img
        :src="`data:image/jpeg;base64, ${tableDetailData.fortune_telling_image}`"
        alt="Image"
        class="default-img type-image"
      />
    </td>
    <td>{{ tableDetailData.fortune_telling_name }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'package'"
    class="canSelect"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.id"
    @click="selectRow(tableDetailData)"
  >
    <td>{{ tableDetailData.package_type }}</td>
    <td>{{ tableDetailData.package_cost }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'ability'"
    class="canSelect"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.id"
    @click="selectRow(tableDetailData)"
  >
    <td>{{ tableDetailData.abilitie_name }}</td>
  </tr>

  <tr
    v-else-if="tableType === 'history'"
    v-for="tableDetailData in tableDetailDatas"
    :key="tableDetailData.id"
  >
    <td>{{ tableDetailData.user_name }}</td>
    <td>{{ tableDetailData.magicial_name }}</td>
    <td>{{ tableDetailData.fortune_telling }}</td>
    <td>{{ format_date_history(tableDetailData.date) }}, {{ tableDetailData.start_time }} - {{ tableDetailData.end_time }} น.</td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  props: ["tableType", "tableDetailDatas", "method"],
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    format_date_history(value) {
      if (value) {
        moment.locale('th');
        return moment(String(value)).format("DD MMMM YYYY");
      }
    },
    openMagicialDetail(id) {
      this.$router.push({ path: `/magicial-detail/${id}` });
    },
    selectRow(data) {
      typeof this.method === "function" && this.method(data);
    },
    unBan(id) {
      typeof this.method === "function" && this.method(id, false);
    },
    ban(id) {
      typeof this.method === "function" && this.method(id, true);
    },
  },
};
</script>

<style scoped>
.wating-btn {
  background-color: white;
  color: black;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  /* cursor: pointer; */
}
.approved-btn {
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  /* cursor: pointer; */
}
.rejected-btn {
  background-color: #e75454;
  color: white;
  padding: 8px 16px;
  border: 2px solid #e75454;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  /* cursor: pointer; */
}
.detail-btn {
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: 2px solid black;
  border-radius: 10px;
  width: 140px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}

tr {
  background: #eee;
  height: 60px;
  border-bottom: 1px solid #5dacc3;
}

tr:last-child {
  border-bottom: none;
}

.canSelect {
  cursor: pointer;
}

.type-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>