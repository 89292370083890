<template>
  <Base :routerName="$route.name">
    <div id="content">
      <DropdownBtn
        :data="dropdownOption[4]"
        :datas="dropdownOption"
        type="changeOption"
        class="dropdownbtn"
      />
      <br />
      <br />
      <br />
      <TableHeader
        :headerTitles="['หมอดู', 'รายได้', 'จำนวนผู้ใช้งานทั้งหมด']"
        tableType="homeSalary"
        :tableDetailDatas="data"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
      <br />
      <BottomBtn @onClickExport="exportExcel" class="exportbtn" />
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import DropdownBtn from "@/components/DropdownButton.vue";
import Page from "@/components/Page.vue";
import BottomBtn from "@/components/BottomButton.vue";
import API from "@/Service/Api";
import * as XLSX from "xlsx";
import moment from "moment";

export default {
  data() {
    return {
      data: null,
      lastPage: 0,
      pages: [],
      dropdownOption: ["หน้าแรก", "คะแนน", "หมอดู", "เวลา", "รายได้"],
    };
  },
  components: {
    Base,
    TableHeader,
    DropdownBtn,
    Page,
    BottomBtn,
  },
  mounted() {
    API.get("admin/dashboard/magicial_salary", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    exportExcel() {
      API.get("admin/dashboard/magicial_salary_all", true).then((response) => {
        if (response.data === null || response.data.length <= 0) {
          return;
        } else {
          var exportData = [];
          response.data.map((object) => {
            exportData.push({
              หมอดู: object.magicial,
              รายได้: object.salary,
              จำนวนผู้ใช้งานทั้งหมด: object.user_count_all,
            });
          });
          const dataWS = XLSX.utils.json_to_sheet(exportData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, dataWS);
          XLSX.writeFile(
            wb,
            `export-รายได้--${this.format_date(Date().toLocaleString())}.xlsx`
          );
        }
      });
    },
    updatePage(page) {
      API.get(`admin/dashboard/magicial_salary?page=${page}`, true).then(
        (response) => {
          this.data = response.data;
        }
      );
    },
  },
};
</script>

<style scoped>
</style>