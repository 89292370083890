<template>
  <Base :routerName="$route.name">
    <div id="content">
      <h2>ประวัติ</h2>
      <TableHeader
        :headerTitles="[
          'บัญชีผู้ใช้',
          'หมอดู',
          'ศาสตร์',
          'วันเวลาที่ให้บริการ',
        ]"
        tableType="history"
        :tableDetailDatas="data"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import Page from "@/components/Page.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      data: null,
      lastPage: 0,
      pages: [],
    };
  },
  components: {
    Base,
    TableHeader,
    Page,
  },
  mounted() {
    API.get("admin/history", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    updatePage(page) {
      API.get(`admin/history?page=${page}`, true).then(
        (response) => {
          this.data = response.data;
        }
      );
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}
</style>
