<template>
  <div class="background-div">
    <img
      src="../assets/acessory_1.png"
      alt="acessory_1"
      id="acessory-1"
      class="default-img"
    />
    <img
      src="../assets/acessory_2.png"
      alt="acessory_2"
      id="acessory-2"
      class="default-img"
    />
    <div class="form-div">
      <img src="../assets/logo.png" alt="Logo" class="default-img" />
      <input type="text" placeholder="ชื่อ" v-model="username" />
      <input type="password" placeholder="รหัสผ่าน" v-model="password" />
      <button @click="login" class="login-button">เข้าสู่ระบบ</button>
    </div>
  </div>
</template>

<script>
import API from "@/Service/Api";

export default {
  data() {
    return {
      username: null,
      password: null,
    };
  },
  components: {},
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = "#004a61";
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = "";
    next();
  },
  methods: {
    login() {
      API.post("admin/login", false, { username: this.username, password: this.password }).then(
        (response) => {
          if (response.isSuccess) {
            localStorage.setItem("apiToken", response.data.token)
            window.location.href = "/";
          } else {
            // popup
          }
        }
      );
    },
  },
};
</script>

<style scoped>
/* html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 30, 47, 1) 0%,
    rgba(0, 74, 97, 1) 23.14%,
    rgba(49, 145, 168, 1) 61.97%,
    rgba(124, 192, 215, 1) 94.68%
  );
} */

.form-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: center;
  padding: 40px;
  border-radius: 20px;
}
input {
  font-family: "Kanit", sans-serif;
  display: inline-block;
  padding: 16px 6px;
  width: 75%;
  box-sizing: border-box;
  margin: 8px 0px;
  border: 1px solid #5dacc3;
  border-radius: 8px;
}
input:focus {
  outline: none;
  border: 1px solid #3191a8;
}
.login-button {
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  display: fixed;
  width: 75%;
  background: #5dacc3;
  padding: 16px;
  border: none;
  border-radius: 10px;
  margin: 10px;
  color: white;
  cursor: pointer;
}
#acessory-1 {
  position: fixed;
  bottom: 25%;
  right: 10%;
}
#acessory-2 {
  position: fixed;
  top: 25%;
  left: 10%;
}
</style>
