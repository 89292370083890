<template>
  <div class="backdrop" @click.self="closePopup">
    <div
      :class="{
        showImageContainer: type === 'showImage',
        confirmCancelContainer: type === 'confirmCancel' || type === 'confirm',
        addData:
          type === 'type' ||
          type === 'type-edit' ||
          type === 'ability' ||
          type === 'ability-edit' ||
          type === 'package' ||
          type === 'package-edit' ||
          type === 'news' ||
          type === 'news-edit',
      }"
    >
      <div class="closeImgContainer" v-if="showCloseIcon">
        <img
          @click="closePopup"
          :src="require('@/assets/ic_close.png')"
          class="closeImage"
        />
      </div>

      <img
        v-if="type === 'showImage'"
        :src="`data:image/jpeg;base64, ${data}`"
        alt="Image"
        class="default-img showImg"
      />

      <div v-if="type === 'confirmCancel'">
        <h1 class="confirmCancelh1">{{ text }}</h1>
        <div class="bottom-btn">
          <button class="approved-btn" @click="confirm">ยืนยัน</button>
          <button class="rejected-btn" @click="cancel">ยกเลิก</button>
        </div>
      </div>

      <div v-if="type === 'confirm'">
        <h1 class="confirmh1">{{ text }}</h1>
        <div class="bottom-btn">
          <button class="approved-btn" @click="confirm">ยืนยัน</button>
        </div>
      </div>

      <div v-if="type === 'type'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column">
            <p class="addDataP">ชื่อ</p>
            <p class="addDataP">รูปภาพ</p>
          </div>
          <div class="column">
            <input class="input-text" type="text" v-model="inputText" />
            <div class="imgContainer">
              <img v-if="url" :src="url" class="default-img addDataImg" />
              <img
                v-else
                :src="require('@/assets/ic_add_image.png')"
                class="default-img"
              />
            </div>
            <input type="file" @change="onFileSelected" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="cancel">ยกเลิก</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'type-edit'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column">
            <p class="addDataP">ชื่อ</p>
            <p class="addDataP">รูปภาพ</p>
          </div>
          <div class="column">
            <input class="input-text" type="text" v-model="inputText" />
            <div class="imgContainer">
              <img
                v-if="isSelectNewImage"
                :src="url"
                class="default-img addDataImg"
              />
              <img
                v-else
                :src="`data:image/jpeg;base64, ${url}`"
                alt="image"
                class="default-img addDataImg"
              />
            </div>
            <input type="file" @change="onFileSelected" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="deleteData">ลบ</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'ability'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column-package">
            <p class="addDataP-package">ความถนัด</p>
          </div>
          <div class="column-package">
            <input class="input-text" type="text" v-model="inputText" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="cancel">ยกเลิก</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'ability-edit'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column-package">
            <p class="addDataP-package">ความถนัด</p>
          </div>
          <div class="column-package">
            <input class="input-text" type="text" v-model="inputText" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="deleteData">ลบ</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'package'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column-package">
            <p class="addDataP-package">แพคเกจ(วัน)</p>
            <p class="addDataP-package">ราคา</p>
          </div>
          <div class="column-package">
            <input class="input-text-margin" type="text" v-model="inputText" />
            <br />
            <input class="input-text-margin" type="text" v-model="inputText2" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="cancel">ยกเลิก</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'package-edit'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column-package">
            <p class="addDataP-package">แพคเกจ(วัน)</p>
            <p class="addDataP-package">ราคา</p>
          </div>
          <div class="column-package">
            <input class="input-text-margin" type="text" v-model="inputText" />
            <br />
            <input class="input-text-margin" type="text" v-model="inputText2" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="deleteData">ลบ</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'news'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column">
            <p class="addDataP">รูปภาพ</p>
          </div>
          <div class="column">
            <div class="imgContainer-news">
              <img v-if="url" :src="url" class="default-img addDataImg-news" />
              <img
                v-else
                :src="require('@/assets/ic_add_image.png')"
                class="default-img"
              />
            </div>
            <input type="file" @change="onFileSelected" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="cancel">ยกเลิก</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>

      <div v-if="type === 'news-edit'">
        <h1 class="addDatah1">{{ text }}</h1>
        <div class="row">
          <div class="column">
            <p class="addDataP">รูปภาพ</p>
          </div>
          <div class="column">
            <div class="imgContainer-news">
              <img
                v-if="isSelectNewImage"
                :src="url"
                class="default-img addDataImg-news"
              />
              <img
                v-else
                :src="url"
                alt="image"
                class="default-img addDataImg-news"
              />
            </div>
            <input type="file" @change="onFileSelected" />
          </div>
        </div>
        <div class="bottom-btn-addData">
          <button class="rejected-btn" @click="deleteData">ลบ</button>
          <button class="save-btn" @click="save">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["showCloseIcon", "type", "text", "data", "method", "deleteMethod"],
  data() {
    return {
      url: null,
      inputText: null,
      inputText2: null,
      imageFile: null,
      isSelectNewImage: false,
    };
  },
  mounted() {
    if (this.type === "type" || this.type === "type-edit") {
      if (this.data) {
        this.inputText = this.data.fortune_telling_name;
        this.url = this.data.fortune_telling_image;
        const url = `data:image/jpeg;base64, ${this.url}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            this.imageFile = new File([blob], "File name", {
              type: "image/jpeg",
            });
          });
      }
    } else if (this.type === "ability-edit") {
      if (this.data) {
        this.inputText = this.data.abilitie_name;
      }
    } else if (this.type === "package-edit") {
      if (this.data) {
        this.inputText = this.data.package_type;
        this.inputText2 = this.data.package_cost;
      }
    } else if (this.type === "news-edit") {
      if (this.data) {
        console.log(this.data);
        this.url = this.data.news_image;
        const url = `data:image/jpeg;base64, ${this.url}`;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            this.imageFile = new File([blob], "File name", {
              type: "image/jpeg",
            });
          });
      }
    }
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("close");
      typeof this.method === "function" && this.method();
    },
    cancel() {
      this.$emit("close");
    },
    save() {
      if (this.type === "type" || this.type === "type-edit") {
        if (this.inputText && this.imageFile) {
          this.$emit("close");
          typeof this.method === "function" &&
            this.method(this.inputText, this.imageFile);
        } else {
          return;
        }
      } else if (this.type === "ability" || this.type === "ability-edit") {
        if (this.inputText) {
          this.$emit("close");
          typeof this.method === "function" && this.method(this.inputText);
        } else {
          return;
        }
      } else if (this.type === "package" || this.type === "package-edit") {
        if (this.inputText && this.inputText2) {
          this.$emit("close");
          typeof this.method === "function" &&
            this.method(this.inputText, this.inputText2);
        } else {
          return;
        }
      } else if (this.type === "news" || this.type === "news-edit") {
        if (this.imageFile) {
          this.$emit("close");
          typeof this.method === "function" && this.method(this.imageFile);
        } else {
          return;
        }
      }
    },
    deleteData() {
      this.$emit("close");
      typeof this.deleteMethod === "function" && this.deleteMethod();
    },
    onFileSelected(e) {
      this.isSelectNewImage = true;
      this.imageFile = e.target.files[0];
      this.url = URL.createObjectURL(this.imageFile);
    },
  },
};
</script>

<style scoped>
.backdrop {
  top: 0;
  z-index: 2;
  margin-left: 000px;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.showImageContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  height: 336px;
  width: 672px;
}

.confirmCancelContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  height: 295px;
  width: 648px;
  background: white;
  border-radius: 20px;
  text-align: center;
}
.confirmCancelh1 {
  margin: 100px 100px 60px 100px;
  vertical-align: middle;
  top: 50%;
}

.confirmh1 {
  margin: 80px 100px 20px 100px;
  vertical-align: middle;
  top: 50%;
}

.closeImgContainer {
  position: absolute;
  width: 100%;
}
.closeImage {
  margin-top: 8px;
  margin-right: 8px;
  float: right;
  cursor: pointer;
}
.showImg {
  border-radius: 20px;
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.approved-btn {
  margin: 16px 50px 10px 10px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  border-radius: 10px;
  width: 140px;
  font-size: 20px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}

.save-btn {
  margin: 16px 10px 10px 20px;
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: 2px solid black;
  border-radius: 10px;
  width: 140px;
  font-size: 20px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}

.rejected-btn {
  background-color: #e75454;
  color: white;
  padding: 8px 16px;
  border: 2px solid #e75454;
  border-radius: 10px;
  width: 140px;
  font-size: 20px;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}

.addData {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  border-radius: 20px;
  background: #5dacc3;
}

.addDatah1 {
  text-align: center;
}

.row {
  display: flex;
  padding: 0 4px 0px 32px;
}
.column {
  float: left;
  width: 10%;
  height: 50%;
  padding: 10px;
}

.column-package {
  float: left;
  padding: 10px;
}

.addDataP {
  font-size: 18px;
  color: white;
  margin: 0px 10px 10px 0px;
}

.addDataP-package {
  font-size: 18px;
  color: white;
  margin: 10px 10px 30px 0px;
}

.imgContainer {
  padding: 8px;
  display: inline-block;
  background: #eaeaea;
  margin: 10px 0px 10px 0px;
  width: 50px;
  height: 50px;
}

.imgContainer-news {
  padding: 8px;
  display: inline-block;
  background: #eaeaea;
  margin: 10px 0px 10px 0px;
  width: 400px;
  height: 150px;
  text-align: center;
}

.addDataImg {
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.addDataImg-news {
  object-fit: contain;
  width: 400px;
  height: 150px;
}

.input-text {
  width: 250px;
  border-radius: 20px;
  border: none;
  padding: 10px;
  padding-left: 20px;
  font-family: "Kanit", sans-serif;
  font-size: 18px;
}
.input-text-margin {
  width: 250px;
  border-radius: 20px;
  border: none;
  padding: 10px;
  padding-left: 20px;
  font-family: "Kanit", sans-serif;
  font-size: 18px;
  margin-bottom: 16px;
}

.bottom-btn-addData {
  padding-right: 20px;
  padding-bottom: 20px;
  float: right;
}
</style>