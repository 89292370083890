<template>
  <table>
    <thead>
      <tr>
        <th v-for="headerTitle in headerTitles" :key="headerTitle">
          {{ headerTitle }}
        </th>
      </tr>
    </thead>
    <!-- <div style="overflow-y:auto; max-height: calc(70vh - 3.5rem);"> -->
    <tbody>
      <TableDetail
        :tableType="tableType"
        :tableDetailDatas="tableDetailDatas"
        :method="method"
      />
    </tbody>
  </table>
</template>

<script>
import TableDetail from "@/components/TableDetail.vue";

export default {
  components: {
    TableDetail,
  },
  props: ["headerTitles", "tableType", "tableDetailDatas", "method"],
};
</script>

<style>
table {
  border-collapse: collapse;
  border-spacing: 1;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  table-layout:fixed;
}

td,
th {
  text-align: center;
}

th {
  height: 48px;
  background-color: #5dacc3;
  border-top: none;
  color: white;
}
</style>