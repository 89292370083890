<template>
  <Base :routerName="$route.name" ref="base">
    <div id="content">
      <h2>รายชื่อผู้ใช้</h2>
      <TableHeader
        :headerTitles="['ชื่อ-นามสกุล', 'อีเมล์', 'สถานะ']"
        tableType="user"
        :tableDetailDatas="data"
        :method="clickButton"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import Page from "@/components/Page.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      data: null,
      lastPage: 0,
      pages: [],
    };
  },
  components: {
    Base,
    TableHeader,
    Page,
  },
  mounted() {
    API.get("admin/user", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    updatePage(page) {
      API.get(`admin/user?page=${page}`, true).then((response) => {
        this.data = response.data;
      });
    },
    clickButton(id, isBan) {
      this.$refs.base.showPopup(
        true,
        "confirmCancel",
        "คุณแน่ใจหรือไม่",
        null,
        () => {
          API.patch("admin/user/ban", true, {
            id: id,
            status: isBan ? 'isBan' : 'normal',
          }).then((response) => {
            if (response.isSuccess) {
               location.reload();
            } else {
              this.$refs.base.showPopup(
                true,
                "confirm",
                response.response.data.message
              );
            }
          });
        }
      );
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}
</style>
