<template>
  <Base :routerName="$route.name" ref="base">
    <div id="content">
      <h2>ศาสตร์การดูดวง</h2>
      <TableHeader
        :headerTitles="['โลโก้', 'ชื่อศาสตร์']"
        tableType="type"
        :tableDetailDatas="data"
        :method="selectRow"
      />
      <br />
      <br />
      <div id="bottompage">
        <Page :lastPage="lastPage" :pages="pages" :method="updatePage" />
      </div>
      <button @click="addType">เพิ่ม</button>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import TableHeader from "@/components/TableHeader.vue";
import Page from "@/components/Page.vue";
import API from "@/Service/Api";

export default {
  data() {
    return {
      data: null,
      lastPage: 0,
      pages: [],
    };
  },
  components: {
    Base,
    TableHeader,
    Page,
  },
  mounted() {
    API.get("admin/fortuneTelling", true).then((response) => {
      this.data = response.data;
      this.lastPage = response.meta.last_page;
      for (let i = 0; i < this.lastPage; i++) {
        this.pages.push(i + 1);
      }
    });
  },
  methods: {
    updatePage(page) {
      API.get(`admin/fortuneTelling?page=${page}`, true).then((response) => {
        this.data = response.data;
      });
    },
    selectRow(data) {
      this.$refs.base.showPopup(
        true,
        "type-edit",
        "แก้ไขศาสาตร์การดูดวง",
        data,
        (name, image) => {
          let formData = new FormData();
          formData.append("fortune_telling_name", name);
          formData.append("fortune_telling_image", image);
          API.post(
            `admin/editfortuneTelling/${data.id}`,
            true,
            formData,
            true
          ).then((response) => {
            if (response.isSuccess) {
              location.reload();
            } else {
              this.$refs.base.showPopup(
                true,
                "confirm",
                response.response.data.message
              );
            }
          });
        },
        () => {
          API.delete(`admin/deletefortuneTelling/${data.id}`, true, null).then(
            (response) => {
              if (response.isSuccess) {
                location.reload();
              } else {
                this.$refs.base.showPopup(
                  true,
                  "confirm",
                  response.response.data.message
                );
              }
            }
          );
        }
      );
    },
    addType() {
      this.$refs.base.showPopup(
        true,
        "type",
        "เพิ่มศาสาตร์การดูดวง",
        null,
        (name, image) => {
          let formData = new FormData();
          formData.append("fortune_telling_name", name);
          formData.append("fortune_telling_image", image);
          API.post("admin/addfortuneTelling", true, formData, true).then(
            (response) => {
              if (response.isSuccess) {
                location.reload();
              } else {
                this.$refs.base.showPopup(
                  true,
                  "confirm",
                  response.response.data.message
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}

button {
  width: 140px;
  height: 44px;
  border-radius: 20px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  font-size: 18px;
  border: 2px solid #5dacc3;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
  float: right;
}
</style>
