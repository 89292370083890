<template>
  <button @click="onClick">Export</button>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('onClickExport')
        }
    }
};
</script>

<style scoped>
button {
  width: 140px;
  height: 44px;
  border-radius: 20px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  border: 2px solid #5dacc3;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
}
</style>