<template>
  <Base :routerName="$route.name" ref="base">
    <div id="content">
      <h2>นโยบายความเป็นส่วนตัว</h2>
      <vue-editor v-model="content"></vue-editor>
      <br>
      <button @click="save">บันทึก</button>
    </div>
  </Base>
</template>

<script>
import Base from "@/components/BaseComponent.vue";
import { VueEditor } from "vue3-editor";
import API from "@/Service/Api";

export default {
  data() {
    return {
      content: null,
    };
  },
  components: {
    Base,
    VueEditor,
  },
   mounted() {
    API.get("admin/policy", true).then((response) => {
      this.content = response.policy
    });
  },
  methods: {
    save() {
      API.post("admin/addPolicy", true, {'text': this.content}).then(
        (response) => {
          if (response.isSuccess) {
               this.$refs.base.showPopup(
              true,
              "confirm",
              'บันทึกสำเร็จ'
            );
          } else {
            this.$refs.base.showPopup(
              true,
              "confirm",
              'บันทึกไม่สำเร็จ'
            );
          }
        }
      );
    },
  },
};
</script>

<style scoped>
#content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  transition: margin-left 0.5s;
  padding: 16px;
}

button {
  width: 140px;
  height: 44px;
  border-radius: 20px;
  background-color: #5dacc3;
  color: white;
  padding: 8px 16px;
  font-size: 18px;
  border: 2px solid #5dacc3;
  font-family: "Kanit", sans-serif;
  cursor: pointer;
  float: right;
}
</style>
